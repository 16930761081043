<template>
    <div>
        <van-overlay :show="loading">
            <div class="wrapper">
                <div style="height: 100px; width: 100px;border-radius: 20px;display: flex;align-items: center;justify-content: center;background-color: #FFF;">
                    <van-loading size="24px" vertical v-if="loading">申请中...</van-loading>
                </div>
            </div>
        </van-overlay>
        <van-nav-bar
            title="提现申请"
            left-text=""
            right-text=""
            left-arrow
            fixed
            placeholder
            @click-left="onClickLeft"
            />
        <van-notice-bar
            left-icon="volume-o"
            :scrollable="true"
            text="提现申请时间 9:00-17:00，提现金额将在1-3个工作日内到账"
        />
        <div style="background-color:#FFF">
            <div style="font-size: 2rem;padding: 30px 0 20px 0;">{{money}}</div>
            <div style="padding-bottom:20px">可提现金额</div>
        </div>
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
            <van-field
                name="cashOutMoney"
                label="提现金额："
                v-model="cashOutMoney"
                placeholder="请输入提现金额"
                :rules="[{ validator: validator, message: '请输入正确的提现金额' }]"
                @input="inputCashOutMoney"
            >
                <template #button>
                    <van-button size="small" color="#D91883" type="primary" native-type="button" @click="cashOutAll">全部</van-button>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="info" color="#D91883" native-type="submit">提交</van-button>
            </div>
        </van-form>
    </div>
</template>
<style>
    .wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

</style>
<script>
import * as commonApi from '@/api/common';
// import { Toast } from 'vant';
import { Dialog } from 'vant';
export default {
    name:'CashOut',
    data(){
        return {
            money:'',
            cashOutMoney:'',
            lock:false,
            loading:false
        }
    },
    mounted:function(){
        this.init()
    },
    methods:{
        cashOutAll(){
            this.cashOutMoney = this.money
        },
        onClickLeft(){
            this.$router.replace({ path: '/my'})
        },
        init(){
            this.getUserScore()
        },
        getUserScore(){
            commonApi.getUserMoney().then(resp=>{
                if(resp.code == 200){
                    this.money = resp.data.money
                }
            })
        },
        // 校验函数返回 true 表示校验通过，false 表示不通过
        validator(val) {
            console.log(val)
            return /^[0-9]+(.[0-9]{1,2})?$/.test(val);
        },
        onFailed(errorInfo) {
            console.log('failed', errorInfo);
        },
        onSubmit(values){
            //增加锁
            if(this.lock){
                return
            }
            this.lock = true
            this.loading = true
            commonApi.cashOut(values).then(resp=>{
                this.loading = false
                this.lock = false
                if(resp.code == 200){
                    this.getUserScore()
                    this.cashOutMoney = "";
                    // Toast(resp.msg)
                    Dialog.alert({
                    message: resp.msg,
                    confirmButtonColor:'#D91883FF'
                    }).then(() => {
                    // on close
                    });
                }
            },
            err=>{
                this.loading = false
                console.log(err)
                this.lock = false
            })
        },
        inputCashOutMoney(e){
            //输入字符转换成数字
            if(e == ""){
                this.cashOutMoney = ""
                return
            }
            let money = e.replace(/[^\d.]/g,"");  //清除“数字”和“.”以外的字符
            money = parseFloat(money)
            if(money > this.money){
                this.cashOutMoney = this.money
            }else{
                this.cashOutMoney = (money)
            }
        }
    }
    
}
</script>