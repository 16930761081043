<template>
    <div>
        <div>
            <div>
                <!-- <div style="width: 100vw; height: 35vw; background-image: url(&quot;http://img.x-club2022.com/image/bg320%401x.png&quot;); background-size: 100vw 35vw; background-repeat: no-repeat;"></div> -->
                <!-- <div style="width: 100vw; height: 35vw; background-color:#D91883FF; background-size: 100vw 35vw; background-repeat: no-repeat;"></div> -->
                <img :src="userInfo.avatar" style="height:15vw;width:15vw;margin-top: 7.5vw;border-radius:7.5vw" alt="用户头像">
                <div style="font-size: 0.95rem; color: #303133FF; margin-top: 0.5vw;">{{userInfo.nickname}}</div>
            </div>
            <div style="border-radius:10px;margin:0 10px">
                <van-cell title="欧x值商城" is-link to="/changeScore" icon="shop-o" style="border-radius:10px 10px 0 0;text-align: left;"/>
                <van-cell title="无限币中心" is-link to="/changePoints" icon="manager-o" style="border-radius:0 0 10px 10px;text-align: left"/>
                <van-cell title="X币中心" v-if="vipFlag" is-link to="/changeXScore" icon="diamond-o" style="border-radius:0 0 10px 10px;text-align: left"/>
                <van-cell title="金币转换X币" v-if="vipFlag" is-link to="/transformScore" icon="diamond-o" style="border-radius:0 0 10px 10px;text-align: left"/>
                <van-cell title="金币中心" is-link to="/changeGold" icon="diamond-o" style="border-radius:0 0 10px 10px;text-align: left"/>
                <van-cell title="金币转换无限币" is-link to="/transformPoints" icon="manager-o" style="border-radius:0 0 10px 10px;text-align: left"/>
                <van-cell title="账户中心" is-link to="/userAccount" icon="manager-o" style="border-radius:0 0 10px 10px;text-align: left"/>
                <van-cell title="申请提现" is-link to="/cashOut" icon="manager-o" style="border-radius:0 0 10px 10px;text-align: left"/>
                <van-cell title="提现记录" is-link to="/cashOutList" icon="manager-o" style="border-radius:0 0 10px 10px;text-align: left"/>
            </div>
        </div>
    </div>
</template>
<style>
    page{
        background-color: rgba(245, 248, 250, 1);
    }
</style>
<script>
import * as commonApi from '@/api/common';
import { Toast } from 'vant';
export default {
    name:'MyIndex',
    data(){
        return {
            userInfo:{
                avatar:''
            },
            vipFlag:false,
            init:false,
        }
    },
    mounted(){
        this.getUserInfo()
    },
    methods:{
        getUserInfo(){
            if(!this.init){
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                });
                commonApi.getUserInfo().then(resp=>{
                    if(resp.code == 200){
                        this.userInfo = resp.data
                        this.init = true
                        this.vipFlag = resp.data.recharge_status == '1'
                        //将vipFlag设置到sessionStorage中
                        sessionStorage.setItem('vipFlag',this.vipFlag)
                    }
                    Toast.clear();
                })
            }
        }
        // gotoPage(e){
        //     console.log(e.currentTarget.dataset.link)
        //     this.$router.push({path:e.currentTarget.dataset.link})
        // }
    }
}
</script>