import axios from "axios";
// import { Toast } from 'vant';
import { Dialog } from 'vant';
//请求头
let headers = {
  "Content-Type": "application/json;charset=UTF-8",
  "mini_version": "1.7.0"
};

//创建axios实例
const service = axios.create({
  timeout: 12000,
  // headers: headers,
  baseURL: process.env.VUE_APP_BASE_API
});

//错误码
const httpCode = headers

//请求拦截器
service.interceptors.request.use(  
  request => {
    // 设置请求的头信息
    headers.token = localStorage.getItem("token") || "";
    request.headers = headers
    return request
  },
  config => {
    // 在发送请求之前做些什么
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

//响应拦截器
service.interceptors.response.use(
  function(response) {
    // 对响应数据做点什么
    if (response.status === 200) {
      return response.data;
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    // 对响应错误做点什么
    let message = "";
    try {
      message =
        error.response.status in httpCode
          ? httpCode[error.response.status]
          : error.response.data.msg || "系统错误，请联系客服";
          if(error.response.status == 401){
            //删除localStorage token
            localStorage.removeItem('token')
            window.location.href = '/'
          }else{
            // Toast({ message });
            Dialog.alert({
              message: message,
              confirmButtonColor:'#D91883FF'
            }).then(() => {
              // on close
            });
          }
    } catch (e) {
      console.log('000000',e);
    }
    return Promise.reject(error);
  }
);
export default service;

