<template>
  <div id="app">
    <div class="isNotweixin" v-if="isNotWechat">
      <div class="isNotweixin-box">
        <!-- <img src="../../static/img/w01.png" alt=""> -->
        <span>仅限微信浏览器访问，请在微信客户端打开链接</span>
      </div>
    </div>
    <template v-else>
      <router-view></router-view>
      <!-- <BottomTab/> -->
    </template>
  </div>
</template>

<script>
// import BottomTab from './components/BottomTab.vue'

export default {
  name: 'App',
  components: {
    // BottomTab
  },
  data() {
		return {
        isNotWechat:false
      }
    },
  mounted() {
      // 如果不是微信浏览器
    if (!this.isWeixinBrowser()) {
      this.isNotWechat = true;
    }
  },
  methods: {
    // 判断是否为微信浏览器
    isWeixinBrowser() {
			let ua = navigator.userAgent.toLowerCase();
			return /micromessenger/.test(ua) ? true : false;
		}
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
