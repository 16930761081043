
<style>
._p{
    -webkit-box-orient: vertical;
    color: #303133FF;
    display: -webkit-box;
    font-size: 1rem;
    height: 5.6vw;
    line-height: 5.6vw;
    width: 56.5vw;  height: 4rem;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
/* toast容器样式 */
.toast-container {
position: fixed;
bottom: 40vh;
left: 50%;
transform: translateX(-50%);
z-index: 9999;
display: flex;
justify-content: center;
align-items: center;
}

/* toast提示样式 */
.toast {
padding: 12px 20px;
background-color: #333;
color: #fff;
border-radius: 4px;
font-size: 16px;
opacity: 0;
animation: fade-in 0.1s ease-in-out forwards;
animation-delay: 0.1s;
}

/* toast淡入动画 */
@keyframes fade-in {
from {
    opacity: 0;
    transform: translateY(40px);
}
to {
    opacity: 1;
    transform: translateY(0);
}
}

.content1{
    background: #fff;
    border-radius: 2.6vw;
    padding: 4vw;
    width: 92vw;
    /* height: 100%; */
}

.content1-list{
    height: 30vw;
    width: 92vw;
    border-bottom: 1px solid #F0F0F0FF;
    display: flex;
    margin-top:4vw;
}
content1-list:last-child{
    border-bottom:0px;
}

.content1-list_img{
    border-radius: 1vw;
    height: 24vw;
    width: 24vw;
}
.content1-list_img>img{
    border-radius: 1vw;
    height: 24vw;
    width: 24vw;
}

.content1-list_text1{
    color: #333;
    font-size: 4vw;
    height: 11.2vw;
    line-height: 5.6vw;
    margin-left: 2.6vw;
    width: 65.6vw;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    text-align: left;
}

.content1-list_text2{
    color: #606266FF;
    float: left;
    font-size: 0.85rem;
    height: 4.8vw;
    line-height: 5vw;
    margin-left: 2.6vw;
    /* width: 36vw; */
}

.content1-list_input{
    color: #333;
    font-size: 0.85rem;
    height: 4.8vw;
    text-align: center;
    width: 7.4vw;
    margin-top: 0rpx;
}

.content1-list_left,.content1-list_right{
    align-items: center;
    display: flex;
    height: 4vw;
    justify-content: center;
    width: 4vw;
    min-height: 1.4rem;
}
.content1-list_left > image,.content1-list_right > image{
    width: 4.2vw;
    height: 4.2vw;
}
input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.van-icon{
    color: black
}
</style>
<template>
    <div>
        <van-nav-bar
            nav-bar-icon-color="#000000"
            title="金币中心"
            left-text=""
            fixed
            right-text=""
            left-arrow
            placeholder
            @click-left="onClickLeft"
            />
        <div class="content1" style="padding-bottom:4vw;margin-bottom: 130px;">
            <div class="content1-list" v-for="(item,index) in data" v-bind:key="item.goods_id">
                <div class="content1-list_img">
                    <img :src="item.image"/>
                </div>
                <div>
                    <div class="content1-list_text1">{{item.name}}</div>
                    <div>
                        <div class="content1-list_text2" style="display: flex;align-items: center;">共有 <div style="color: #D91883FF;font-weight: 700;margin: 5rpx 5rpx 0 5rpx;">{{item.allNum}}</div> 个可提交
                            <div @click="clickLocked" :data-index="item.goods_id" :data-islocked="item.idLocked" style="display:inline-block;margin-top: 5rpx;margin-left: 10rpx;">
                                <img style='height: 4vw;width: 4vw;' :src="item.idLocked == '1'?'https://img.x-club2022.com/image/cabinet_locked1@2x.png':'https://img.x-club2022.com/image/cabinet_locked0@2x.png'"/>
                            </div>
                        </div>
                    <div style="margin-top: 4vw;display: flex;height: 4vw;align-items: center;justify-content:flex-end">
                        <div id="score-num- goods_id " data-score=" score " style="color: #D91883FF;margin-right: 3vw;font-weight: 400;font-size: 0.8rem;display: (sendScore>0?'block':'none');">{{item.score}}</div>
                        <div class="content1-list_left" @click="diffNum" :data-islocked="item.idLocked" :data-index="index">
                            <img mode style="height: 4.2vw;width: 4.2vw;" src="https://img.x-club2022.com/image/icon_del%403x.png"/>
                        </div>
                    <input class="content1-list_input numeric-input" style="margin:0 3px"  type="number" v-model="item.sendNum" :disabled="item.idLocked == '1'"/>
                    <div class="content1-list_right" :data-index="index" :data-islocked="item.idLocked" @click="addNum">
                        <img style="height: 4.2vw;width: 4.2vw;" src="https://img.x-club2022.com/image/icon_add%403x.png"/>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="position:fixed;bottom: 0;height: 120px;width: 100%;background-color:white;padding-bottom: 20px;box-shadow: 0px -2px 8px 0px #00000014;">
            <div style="display: flex;justify-content: space-between;height: 4vw;margin-top: 4vw;width: 92vw;margin-left:4vw;margin-right:4vw;align-items: center;">
                <div class="bottom-text" id="allScore" style="color: #D91883FF;" >共{{allScore}}金币</div>
                <div style="display: flex;justify-content: center;">  
                <div style="display: flex;align-items: center;">
                    <template v-if="canSelectAll == true">
                        <div @click="selectAll" class="bottom-img" >
                            <image style="height: 4vw;width: 4vw;margin-right: 3px;" mode src="https://img.x-club2022.com/image/icon_check2_0%403x.png"></image>
                        </div>
                        <div @click="selectAll" class="bottom-text" >全部添加</div>
                    </template>
                    <template v-else>
                        <div @click="cancelSelectAll" class="bottom-img" >
                            <image style="height: 4vw;width: 4vw;margin-right: 3px;" mode src="https://img.x-club2022.com/image/icon_check2_1%403x.png"></image>
                        </div>
                        <div @click="cancelSelectAll" class="bottom-text" >取消全部</div>
                    </template>
                </div>
                </div>
            </div>
            <div style="display: flex;justify-content: center;align-items: center;margin-top: 20px;">
                <div style="width: 80vw;height: 50px;background-color: #D91883FF;border-radius: 2vw;color: white;font-size: 0.9rem;display: flex;justify-content: center;align-items: center;" @click="mySubmit">提交</div>
            </div>
        </div>
    </div>
</template>
<script>
import * as commonApi from '@/api/common';
import { Toast,Dialog } from 'vant';
export default {
    name:'ChangeXScore',
    data(){
        return {
            data:[],
            allScore:0,
            canSelectAll:true,
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        onClickLeft(){
            this.$router.replace({ path: '/my'})
        },
        diffNum(e){
            let index = e.currentTarget.dataset.index;
            let isLocked = e.currentTarget.dataset.islocked;
            if(isLocked == '1'){
                return;
            }
            if(this.data[index].sendNum > 0){
                this.data[index].sendNum--;
            }
            this.countScore();
        },
        addNum(e){
            let index = e.currentTarget.dataset.index;
            let isLocked = e.currentTarget.dataset.islocked;
            if(isLocked == '1'){
                return;
            }
            if(this.data[index].sendNum < this.data[index].allNum){
                this.data[index].sendNum++;
            }
            this.countScore();
        },
        countScore(){
            let allScore = 0;
            for(let i = 0;i<this.data.length;i++){
                allScore += this.data[i].sendNum * this.data[i].score;
            }
            this.checkIsSelectAll();
            this.allScore = allScore;
        },
        mySubmit(){
            let allScore = 0;
            for(let i = 0;i<this.data.length;i++){
                allScore += this.data[i].sendNum * this.data[i].score;
            }
            if(allScore == 0){
                Toast('请至少选择一个商品');
                return;
            }
            Dialog.confirm({
                title: '提示',
                message: '金币仅支持提现，转换后不可撤回？',
                confirmButtonColor:"#D91883FF"
            }).then(() => {
                // on confirm
                this.submitChange();
            }).catch(() => {
                // on cancel
            });
        },
        selectAll(){
            for(let i = 0;i<this.data.length;i++){
                //跳过 已锁定的商品
                if(this.data[i].isLocked){
                    continue;
                }
                this.data[i].sendNum = this.data[i].allNum;
            }
            this.canSelectAll = false
            this.countScore();
        },
        cancelSelectAll(){
            for(let i = 0;i<this.data.length;i++){
                this.data[i].sendNum = 0;
            }
            this.canSelectAll = true
            this.countScore();
        },
        checkIsSelectAll(){
            let isSelectAll = true;
            for(let i = 0;i<this.data.length;i++){
                //跳过 已锁定的商品
                if(this.data[i].isLocked){
                    continue;
                }
                if(this.data[i].sendNum != this.data[i].allNum){
                    isSelectAll = false;
                    break;
                }
            }
            this.canSelectAll = !isSelectAll;
        },
        getData(){
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            commonApi.getMoneyList().then(resp=>{
                if(resp.code == 200){
                    this.data = resp.data;
                }
                Toast.clear();
            })
        },
        submitChange(){
            //获取选中的商品 以 id:num 的形式
            let goods = [];
            for(let i = 0;i<this.data.length;i++){
                if(this.data[i].sendNum > 0){
                    goods.push({
                        id:this.data[i].goods_id,
                        num:this.data[i].sendNum
                    })
                }
            }
            //将goods转换成json字符串
            let goodsJson = JSON.stringify(goods);
            console.log(goodsJson);
            commonApi.changeMoney({goodsInfo:goodsJson}).then(resp=>{
                if(resp.code == 200){
                    Toast(resp.msg);
                    this.getData();
                }
            })
        },
        clickLocked(e){
            let isLocked = e.currentTarget.dataset.islocked;
            let id = e.currentTarget.dataset.index;
            isLocked = isLocked == '0' ? '1': '0'
            commonApi.cabinetLock({isLocked:isLocked,id:id}).then(resp=>{
                console.log(resp);
                if(resp.code == 200){
                    Toast('操作成功');
                    this.getData();
                }
            })
        }
    },

}
</script>