
import  request from '@/service'

// 列表查询
export const login = params => request({url: '/api/wechat/login',method: 'get',params})

export const getBannerList = params => request({url: '/api/index/bannerList',method: 'get',params})

export const getLockerList = params => request({url: '/api/wechat/getLockerList',method: 'get',params})

export const getMoneyList = params => request({url: '/api/wechat/getMoneyList',method: 'get',params})

export const getPointsList = params => request({url: '/api/wechat/getPointsList',method: 'get',params})

export const getScoreShopList = params => request({url: '/api/wechat/getScoreShopList',method: 'get',params})

export const changeScoreShopGoods = params => request({url: '/api/wechat/changeScoreShopGoods',method: 'get',params})

export const changeXScore = params => request({url: '/api/wechat/changeXScore',method: 'get',params})

export const changeMoney = params => request({url: '/api/wechat/changeMoney',method: 'get',params})

export const changePoints = params => request({url: '/api/wechat/changePoints',method: 'get',params})

export const cabinetLock = params => request({url: '/api/wechat/cabinetLock',method: 'get',params})

export const getUserInfo = params => request({url: '/api/wechat/userInfo',method: 'get',params})

export const getUserMoney = params => request({url: '/api/wechat/userMoney',method: 'get',params})

export const getUserScore = params => request({url: '/api/wechat/userScore',method: 'get',params})

export const userAccount = params => request({url: '/api/wechat/userAccount',method: 'get',params})

export const createAccount = params => request({url: '/api/wechat/createAccount',method: 'get',params})

export const cashOut = params => request({url: '/api/wechat/cashOut',method: 'get',params})

export const transformPoints = params => request({url: '/api/wechat/transformPoints',method: 'get',params})

export const transformScore = params => request({url: '/api/wechat/transformScore',method: 'get',params})

export const cashOutList = params => request({url: '/api/wechat/cashOutList',method: 'get',params})


