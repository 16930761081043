import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import { Tabbar, TabbarItem,Toast,Dialog,Cell, CellGroup, NavBar, Form, Field,Button ,NoticeBar,Loading,Overlay} from 'vant';
import router from './router'
import Wx from 'weixin-js-sdk' // 引入微信SDK
// 引入全部样式	
import 'vant/lib/index.less';
// //引入想修改的组件的单独less文件
import 'vant/lib/nav-bar/style/less';

Vue.config.productionTip = false
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Toast);
Vue.use(Loading);
Vue.use(Overlay);
Vue.use(NavBar);
Vue.use(NoticeBar);
Vue.use(Cell);
Vue.use(Field);
Vue.use(Button);
Vue.use(Form);
Vue.use(CellGroup);
Vue.use(Dialog);
Vue.use(Wx);
// Vue.use(axios);
Vue.use(VueRouter);



new Vue({
  render: h => h(App),
  router:router
}).$mount('#app')
