<template>
    <div>
    </div>
</template>
<script>
import * as commonApi from '@/api/common';
export default {
    name:'HomeIndex',
    mounted(){
        this.initToken()
    },
    methods:{
        getBannerList(){
            commonApi.getBannerList().then(resp=>{
                console.log(resp)
            })
        },
        //getUrlParam方法就是使用正则截取地址栏里的code
        getUrlParam: function (name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            var r = window.location.search.substr(1).match(reg);
            if (r != null) return unescape(r[2]);
            return null;
        },
        getToken(wx_code){
            commonApi.login({code:wx_code}).then(resp=>{
                console.log(resp)
                if(resp.code == 200){
                    localStorage.setItem('token',resp.data.token);
                    this.$router.replace({ path: '/my'})
                }
                window.history.replaceState({}, '', location.pathname);
            })
        },
        initToken(){
            //获取TOKEN
            var token = localStorage.getItem('token');
            if(token == null){
                // var appid = "wx4dfd865f7e456108"; //公众号appid  测试
                var appid = "wx34053a98ac88e28b"; //公众号appid
                var redirect = encodeURIComponent(window.location.href); //重定向回来的地址
                var wx_code = this.getUrlParam("code"); // 截取url中的code
                //获取code的地址。获取成功重定向后地址栏中将会带有code，判断没有code的话，就跳转到微信官方链接上获取，获取成功后会再重定向回来，注意url是需要使用encodeURIComponent处理一下编码的
                if (!wx_code) {
                    // scope: 必传；应用授权作用域，snsapi_base （不弹出授权页面，直接跳转，只能获取用户openid），snsapi_userinfo （弹出授权页面，可通过openid拿到昵称、性别、所在地。并且， 即使在未关注的情况下，只要用户授权，也能获取其信息 ）
                    // 静默授权
                    window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect + "&response_type=code&scope=snsapi_userinfo&state=12345#wechat_redirect";
                } else {
                    // 获取到了code
                    this.getToken(wx_code); //把code传给后台获取用户信息
                }
            }else{
                this.$router.replace({ path: '/my'})
            }
        }
    }
}
</script>