<template>
    <div>
        <van-nav-bar
            title="欧x值商城"
            left-text=""
            right-text=""
            left-arrow
            fixed
            @click-left="onClickLeft"
            />
        <!-- <div style="width: 100vw;margin-top: 46px;height: 35vw;background-image: url(http://img.x-club2022.com/image/bg320%401x.png);background-size: 100vw 35vw;background-repeat: no-repeat;"> -->
        <!-- <div style="width: 100vw;margin-top: 46px;height: 35vw;background-color: #D91883FF;background-size: 100vw 35vw;background-repeat: no-repeat;"> -->
        <!-- </div> -->
        <div class="x-score-bar">
            <div class="x-score-bar-left">
                <div class="x-score-bar-left-top">
                    <div style="font-size: 1rem;">剩余欧x值</div>
                    <div style="width:10vw;height:1rem" @click="showRuleDialog">
                        <img style="height: 1rem;width: 1rem;" src="https://img.x-club2022.com/image/icon_quest%403x.png" alt="">
                    </div>
                </div>
                <div class="x-score-bar-left-bottom"> {{score}} </div>
            </div>
        </div>
        <div style="margin-top: 3vw;padding: 0 4vw;display: flex;align-items: center;height: 5.8vw;margin-bottom: 2vw;">
            <img src="https://img.x-club2022.com/image/icon_change%403x.png" style="height: 4vw; width: 4.5vw;margin-right: 1.5vw;">
            <div style="font-size: 1.1rem;color: #303133FF;">欧x值兑换</div>
        </div>
        <div style="display: flex;flex-wrap: wrap;" >
            <div class="commodity-list" v-for="(item,index) in list" v-bind:key="index">
                <img class="list-image" mode="" :src="item.image">
                <div style="margin-left: 2.6vw;display: flex;justify-content: space-between;flex-direction: column">
                    <div class="_p">{{item.name}}</div>
                    <div style="margin-top: 1.6vw;font-size: 0.95rem;color: #606266FF;">剩{{item.stock}}个</div>
                        <div style="height: 7vw;position: relative;line-height: 1;display: flex;">
                        <div style="position: absolute;bottom: 0;height: 5vw;color: #D91883FF;font-family: 'd-din';font-size: 5.3vw;width: 56.5vw;">{{item.score}}<span style="color: #D91883FF;height: 4vw;font-size: 0.8rem;width: 6.6vw;margin-bottom: 1px;">欧x值</span>
                        <div :data-score="item.score" :data-title="item.name" :data-index="item.id" @click="change" style="display: inline-block;height: 7vw;line-height: 7vw;background-color: #D91883FF;width: 15.4vw;color: white;border-radius: 4vw;font-size: 0.9rem;text-align: center;position: absolute;right: 0;margin-top: -2vw;" :style="score > item.score? 'opacity: 1':'opacity: 0.6'">
                            立即兑
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="height: 100px;width: 100vw;"></div>
        </div>
        <div id="dialog" v-show="isShowRule">
            <div style="height: 100vh;width:100vw;position: fixed;top:0;left: 0;z-index: 1000;display: flex;justify-content: center;align-items: center;">
                <div style="width:70vw;height: 90vw;background-color: white;z-index: 999;border-radius: 4vw;color: #303133FF;">
                    <div style="font-size: 1.1rem;text-align: center;margin-top: 3vw;">欧X值</div>
                    <div style="padding:4vw;height: 52vw;overflow-y: scroll;font-size: 0.9rem;">
                        <div style="font-size: 0.9rem;text-align: left;font-weight: bold;">如何获得欧X值：</div>
                        <div style="font-size: 0.85rem;text-align: left">1、平台内参与任意玩法抽赏，可随商品掉落数值不等的欧X值；</div>
                        <div style="font-size: 0.85rem;text-align: left">2、参与签到、试手气、每日任务可获得欧X值。</div>
                        <div style="font-size: 0.85rem;text-align: left">3、参与平台活动可获得欧X值，具体看活动相关规则。</div>
                        <div style="font-size: 0.9rem;text-align: left;font-weight: bold;">如何使用欧X值：</div>
                        <div style="font-size: 0.85rem;text-align: left">1、欧X值可在欧X商城用于道具及商品的兑换，后续积分商城内容会逐渐丰富。</div>
                        <div style="font-size: 0.9rem;text-align: left;font-weight: bold;">欧X值失效和扣减规则：</div>
                        <div style="font-size: 0.85rem;text-align: left">1、欧X值统计周期为月，每月月初第一天00:00:00启动，月末最后一天23:59:59失效，如过时未兑换，后续不补，请及时消耗欧x值；</div>
                        <div style="font-size: 0.85rem;text-align: left">2、消费获得欧X值后若产生退款行为，客服会根据具体情况扣减；欧X值消耗完产生退款行为，客服会按消耗比列进行退款；</div>
                        <div style="font-size: 0.85rem;text-align: left">3、欧X值产生任何问题可联系客服进行处理。</div>
                        <div style="font-size: 0.85rem;text-align: left">   客服V：X-CLUB2022</div>
                    </div>
                    <div @click="hideRuleDialog" style="margin-left:15vw;margin-top:5vw;height:10vw;width:40vw;background-color:#D91883FF;color:white;border-radius:5vw;font-size: 0.95rem;text-align: center;line-height: 10vw;">确认</div>
                </div>
                <div style="position: absolute;height: 100vh;width: 100vw;background-color: rgba(0, 0, 0, 0.8);z-index: 888;"></div>
            </div>
        </div>
    </div>
</template>
<script>
import * as commonApi from '@/api/common';
import { Toast,Dialog } from 'vant';

export default {
    name:'ChangeScore',
    data(){
        return {
            isShowRule:false,
            list:[],
            score:0,
        }
    },
    mounted(){
        this.getData();
    },
    methods:{
        onClickLeft(){
            this.$router.replace({ path: '/my'})
        },
        async getData(showLoading = true){
            if(showLoading){
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                });
            }
            commonApi.getScoreShopList().then(resp=>{
                if(resp.code == 200){
                    this.list = resp.data.list;
                    this.score = resp.data.score;
                }
                if(showLoading){
                    Toast.clear();
                }
            })
        },
        hideRuleDialog(){
            this.isShowRule = false;
        },
        showRuleDialog(){
            this.isShowRule = true;
        },
        change(e){
            let id = e.target.dataset.index
            let score = e.target.dataset.score
            let title = e.target.dataset.title
            //判断是否能兑换
            if(this.score < score){
                Toast('欧x值不足')
                return
            }
            let message = '是否使用'+ score +'欧X值兑换';
            Dialog.confirm({
                title: title,
                message: message,
                confirmButtonColor:"#D91883FF"
            })
            .then(() => {
                // on confirm
                commonApi.changeScoreShopGoods({id:id}).then(resp=>{
                    if(resp.code == 200){
                        this.getData(false)
                        Toast(resp.msg)
                    }
                })
            })
            .catch(() => {
                // on cancel
            });

        }   
    }
}
</script>
<style>
        .x-score-bar{
        padding: 4vw;width: 84vw;background-color: white;border-radius: 2.6vw;margin: 14vw 4vw 0 4vw;display: flex;justify-content: space-between;align-items: center;
        }
        .x-score-bar-left-top{
            height:5.6vw;
            color: #606266FF;
            display: flex;
            justify-content: left;
            align-items: center;
        }
        .x-score-bar-left-top > image{
            height: 4.8vw;
            width: 4.8vw;
        }
        .x-score-bar-left-bottom{
            margin-top: 2.6vw;
            height: 8vw;
            line-height: 8vw;
            font-family: 'd-din';
            font-size: 8vw;
            color: #303133FF;
            text-align: left;
        }
        .x-score-bar-right{
            width: 24.5vw;
            height: 8.5vw;
            border: 1px solid #D91883FF;
            line-height: 8vw;
            text-align: center;
            color: #D91883FF;
            border-radius: 4vw;
            font-size: 1rem;
        }


        .commodity-list{
            background: #fff;
            border-radius: 2.6vw;
            height: 24.5vw;
            margin-left: 4vw;
            overflow: hidden;
            width: 84vw;
            margin-bottom: 2.6vw;
            padding: 2.6vw;
            display: flex;
            text-align: left;
        }
        .list-image{
            height: 23vw;
            width: 23vw;
            border-radius: 1vw;
        }
        ._p{
            -webkit-box-orient: vertical;
            color: #303133FF;
            display: -webkit-box;
            font-size: 1rem;
            height: 5.6vw;
            line-height: 5.6vw;
            width: 56.5vw;  height: 4rem;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        /* toast容器样式 */
        .toast-container {
        position: fixed;
        bottom: 40vh;
        left: 50%;
        transform: translateX(-50%);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        }
        
        /* toast提示样式 */
        .toast {
        padding: 12px 20px;
        background-color: #333;
        color: #fff;
        border-radius: 4px;
        font-size: 16px;
        opacity: 0;
        animation: fade-in 0.5s ease-in-out forwards;
        animation-delay: 0.5s;
        }
        
        /* toast淡入动画 */
        @keyframes fade-in {
        from {
            opacity: 0;
            transform: translateY(40px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
        }
    </style>