import VueRouter from "vue-router";

import HomeIndex from "../pages/HomeIndex.vue"
import MyIndex from "../pages/MyIndex.vue"
import ChangeScore from "../pages/ChangeScore.vue"
import ChangeXScore from "../pages/ChangeXScore.vue"
import ChangeGold from "../pages/ChangeGold.vue"
import ChangePoints from "../pages/ChangePoints.vue"
import UserAccount from "../pages/UserAccount.vue"
import CashOut from "../pages/CashOut.vue"
import CashOutList from "../pages/CashOutList.vue"
import TransformPoints from "../pages/TransformPoints.vue"
import TransformScore from "../pages/TransformScore.vue"

const router = new VueRouter({
    routes: [
        {
            path: "/",
            component:HomeIndex,
            meta:{
                title:"首页",
                vipFlagCheck:false
            }
        },
        {
            path: "/changeScore",
            component:ChangeScore,
            meta:{
                title:"X值商城",
                vipFlagCheck:false
            }
        },
        {
            path: "/changeXScore",
            component:ChangeXScore,
            meta:{
                title:"X币中心",
                vipFlagCheck:true
            }
        },
        {
            path: "/changeGold",
            component:ChangeGold,
            meta:{
                title:"金币中心",
                vipFlagCheck:false
            }
        },
        {
            path: "/changePoints",
            component:ChangePoints,
            meta:{
                title:"无限币中心",
                vipFlagCheck:false
            }
        },
        {
            path: "/transformPoints",
            component:TransformPoints,
            meta:{
                title:"金币转换无限币",
                vipFlagCheck:false
            }
        },
        {
            path: "/transformScore",
            component:TransformScore,
            meta:{
                title:"金币转换X币",
                vipFlagCheck:false
            }
        },
        {
            path: "/my",
            component:MyIndex,
            meta:{
                title:"我的",
                vipFlagCheck:false
            }
        },
        {
            path: "/userAccount",
            component:UserAccount,
            meta:{
                title:"账户中心",
                vipFlagCheck:false
            }
        },
        {
            path: "/cashOut",
            component:CashOut,
            meta:{
                title:"提现",
                vipFlagCheck:false
            }
        },
        {
            path: "/cashOutList",
            component:CashOutList,
            meta:{
                title:"提现记录",
                vipFlagCheck:false
            }
        }
    ]
});

// // 全局前置守卫
// router.beforeEach((to, from, next) => {
//     console.log('to',to)
//     let vipFlagCheck = to.meta.vipFlagCheck??false
//     console.log(vipFlagCheck)
//     if(!vipFlagCheck){
//         next()
//         return
//     }
//     //获取从sessionStorage中获取vipFlag
//     let vipFlag = sessionStorage.getItem('vipFlag')
//     console.log('vipFlag',vipFlag)
//     // 是否有权限
//     if (vipFlag == "true") {
//       next()
//     } else {
//       // 没有权限
//       router.push('/my').catch(() =>{})
//     }
//   })


export default router;