
<template>
    <div>
        <van-nav-bar
            title="账户中心"
            left-text=""
            right-text=""
            left-arrow
            fixed
            @click-left="onClickLeft"
            placeholder
            />
        <van-form validate-first @failed="onFailed" @submit="onSubmit">
            <!-- 通过 pattern 进行正则校验 -->
            <van-field
                v-model="name"
                name="name"
                label="账户姓名："
                placeholder="账户姓名"
                required
                :readonly="readonly"
                :rules="[{ patternName, message: '请输入正确内容' }]"
            />
            <!-- 通过 validator 进行函数校验 -->
            <van-field
                v-model="certNo"
                name="certNo"
                label="身份证号："
                placeholder="身份证号"
                required
                :readonly="readonly"
                :rules="[{ patternCertNo, message: '请输入正确内容' }]"
            />
            <!-- 通过 validator 进行异步函数校验 -->
            <van-field
                v-model="cardNo"
                name="cardNo"
                label="银行卡号："
                placeholder="银行卡号"
                required
                :readonly="readonly"
                :rules="[{ patternCardNo, message: '请输入正确内容' }]"
            />
            <van-field
                v-model="mobile"
                name="mobile"
                label="银行卡预留手机号："
                placeholder="银行卡预留手机号"
                required
                :readonly="readonly"
                :rules="[{ patternMobile, message: '请填写银行卡预留手机号' }]"
            />
            <van-field
                name="status"
                label="当前状态："
                v-model="statusStr"
                :readonly="true"
            />
            <div style="margin: 16px;">
                <van-button round block type="info" color="#D91883" native-type="submit" :disabled="readonly">提交</van-button>
            </div>
        </van-form>
        <div></div>
        <div style="text-align:left;padding:10px">
            <div style="font-weight: bold;text-align:left;">注意：</div>
            <div>1.请准确填写用户姓名和身份证号码；</div>
            <div>2.请确保银行卡号与身份证号相匹配；</div>
            <div>3.手机号码填写银行卡在银行预留的手机号码。</div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import * as commonApi from '@/api/common';
export default {
    name:'UserAccount',
    data(){
        return {
            certNo:'',
            name:'',
            cardNo:'',
            mobile:'',
            status:'-1',
            readonly:false,
            statusStr:'',
            patternName:/^[\u4e00-\u9fa5]{2,4}$/,
            patternCertNo:/^(\d{15}$|^\d{18}$|^\d{17}(\d|X|x))$/,
            patternCardNo:/^([1-9]{1})(\d{14}|\d{18})$/,
            patternMobile:/^1\d{10}$/,
        }
    },
    mounted(){
        this.init()
    },
    methods:{
        init(){
            this.getUserAccount();
        },
        // 校验函数返回 true 表示校验通过，false 表示不通过
        validator(val) {
            return /1\d{10}/.test(val);
        },
        // 异步校验函数返回 Promise
        asyncValidator(val) {
            return new Promise((resolve) => {
                Toast.loading('验证中...');
                setTimeout(() => {
                Toast.clear();
                resolve(/\d{6}/.test(val));
                }, 1000);
            });
        },
        onFailed(errorInfo) {
            console.log('failed', errorInfo);
        },
        onClickLeft(){
            this.$router.replace({ path: '/my'})
        },
        onSubmit(values){
            this.createUserAccount(values)
        },
        getUserAccount(){
            commonApi.userAccount().then(resp=>{
                console.log(resp)
                if(resp.code == 200){
                    this.certNo = resp.data.certNo
                    this.name = resp.data.name
                    this.cardNo = resp.data.cardNo
                    this.mobile = resp.data.mobile
                    this.status = resp.data.status
                    this.statusStr = resp.data.statusStr
                    this.readonly = resp.data.readonly
                }
            })
        },
        createUserAccount(params){

            commonApi.createAccount(params).then(resp=>{
                if(resp.code == 200){
                    this.certNo = resp.data.certNo
                    this.name = resp.data.name
                    this.cardNo = resp.data.cardNo
                    this.mobile = resp.data.mobile
                    this.status = resp.data.status
                    this.statusStr = resp.data.statusStr
                    this.readonly = resp.data.readonly
                }
            })

        }
    }
}
</script>