<template>
    <div>
        <van-nav-bar
            title="提现记录"
            left-text=""
            right-text=""
            left-arrow
            fixed
            placeholder
            @click-left="onClickLeft"
            />
        <div v-if="list.length > 0" class="cash-out-list">
            <template v-for="item in list" >
                <div class="cash-out-item" v-bind:key="item.orderSign" >
                    <div class="item-top">
                        <div>提现单号：</div>
                        <div>{{item.orderSign}}</div>
                    </div>
                    <div class="item-top">
                        <div>申请时间：</div>
                        <div>{{item.createTime}}</div>
                    </div>
                    <div class="item-top">
                        <div>提现金额：</div>
                        <div>{{item.money}}</div>
                    </div>
                    <div class="item-top">
                        <div>当前状态：</div>
                        <div>{{item.status}}</div>
                    </div>
                </div>
            </template>
        </div>
        <div v-else style="text-align:center;padding:100px">暂无提现记录</div>
    </div>
</template>
<style>
    .cash-out-list{
        background-color:#FFF;
        padding: 10px;
        margin: 10px;
        display: flex;
        flex-direction: column;
    }
    .item-top{
        display: flex;
        justify-content:space-between
    }
    .cash-out-item{
        border-bottom: 1px solid #E5E5E5;
        padding: 10px 0;
    }
    .cash-out-item:last-child{
        border-bottom: 0px solid #E5E5
    }

</style>
<script>
import * as commonApi from '@/api/common';
export default {
    name:'CashOut',
    data(){
        return {
            list:'',
        }
    },
    mounted:function(){
        this.getCashOutList()
    },
    methods:{
        getCashOutList(){
            commonApi.cashOutList().then(res=>{
                if(res.code == 200){
                    this.list = res.data.list
                }
            })
        },
        onClickLeft(){
            this.$router.replace({ path: '/my'})
        },
    }
}
</script>